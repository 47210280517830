.chatmain2 {
    width: 27%;
}

.logo-search {
    position: relative;
    width: 100%;
    height: 100px;
}

.company-logo {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    mix-blend-mode: hard-light;

}

.company-logo img {
    width: 70%;
    height: 100%;
    object-fit: contain;
}

.seacrh-bar input {
    max-width: 100%;
    height: auto;
}

.seacrh-bar :placeholder-shown {
    font-size: 15px;
}

.form-control:focus {
    box-shadow: none !important;
    border-color: lightgray !important;
}

.chat-message-list2 {
    padding: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 85vh;
    transition: 0.5s;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.chat-message-list2::-webkit-scrollbar {
    display: none;

}

.profilePictureAll {
    display: flex;
    justify-content: center;
    -webkit-align-items: center;
    border-radius: 50%;
    width: 2.5vw;
    height: 2.5vw;
    font-size: 16px;
    padding: 2px;
    margin-right: 0.5vw;
    font-weight: 500;
    background-color: yellow;
}


.profilePicture2All {
    display: flex;
    justify-content: center;
    -webkit-align-items: center;
    border-radius: 50%;
    width: 2.5vw;
    height: 2.5vw;
    font-size: 16px;
    padding: 2px;
    margin-right: 0.5vw;
    font-weight: 500;
    background-color: green;
}

.profilePicture3All {
    display: flex;
    justify-content: center;
    -webkit-align-items: center;
    border-radius: 50%;
    width: 2.5vw;
    height: 2.5vw;
    font-size: 16px;
    padding: 2px;
    margin-right: 0.5vw;
    font-weight: 500;
    background-color: red;
}

.profilePictureMessage {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    -webkit-align-items: center;
    border-radius: 50%;
    width: 2.5vw;
    height: 2.5vw;
    font-size: 16px;
    padding: 2px;
    margin-right: 0.5vw;
    font-weight: 500;
    background-color: rgba(0, 0, 212, 0.671);
}

.lastMessage2 {
    justify-content: flex-start;
    max-width: 72%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lastMessage2 {
    padding-left: 3.8vw !important;
    font-size: 14px !important;
    opacity: 0.9;
    /* max-width: 72%; */
}


.time_lastMessage2 {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.time2 {
    font-size: 12px;
}

@media only screen and (max-width: 1280px) {
    .profilePictureAll {
        width: 4vw;
        height: 4vw;
        font-size: 15px;
    }

    .profilePicture2All {
        width: 4vw;
        height: 4vw;
        font-size: 15px;
    }
    .profilePicture3All {
        width: 4vw;
        height: 4vw;
        font-size: 15px;
    }

    .profilePictureMessage {
        width: 4vw;
        height: 4vw;
        font-size: 15px;
    }

    .lastMessage2 {
        padding-left: 5.4vw !important;
    }

    .chat-message-list2 {
        height: 81vh;
    }

}



@media only screen and (max-width: 990px) {
    .profilePictureAll {
        width: 7vw;
        height: 7vw;
        font-size: 15px;
    }

    .profilePicture2All {
        width: 7vw;
        height: 7vw;
        font-size: 15px;
    }

    .profilePicture3All {
        width: 7vw;
        height: 7vw;
        font-size: 15px;
    }

    .profilePictureMessage {
        width: 5vw;
        height: 5vw;
        font-size: 12px;
    }

    .lastMessage2 {
        padding-left: 11.2vw !important;
    }

    .chatmain2 {
        display: none;
    }

    .backgroundImageM {
        width: 100%;
    }

}

@media only screen and (max-width: 998px) {
    .profilePictureAll {
        width: 5vw;
        height: 5vw;
    }

    .profilePicture2All {
        width: 4vw;
        height: 4vw;
    }
    .profilePicture3All {
        width: 4vw;
        height: 4vw;
    }

    .lastMessage2 {
        padding-left: 6.3vw !important;
    }

}

@media only screen and (max-width: 630px) {
    .profilePictureAll {
        width: 7vw;
        height: 7vw;
        font-size: 15px;
    }

    .profilePicture2All {
        width: 5vw;
        height: 5vw;
    }
    .profilePicture3All {
        width: 5vw;
        height: 5vw;
    }

    .profilePictureMessage {
        width: 9vw;
        height: 9vw;
        font-size: 14px;
    }

    .lastMessage2 {
        padding-left: 8.3vw !important;
    }

}

@media only screen and (max-width: 500px) {
    .profilePictureAll {
        width: 6vw;
        height: 6vw;
    }

    .profilePicture2All {
        width: 6vw;
        height: 6vw;
    }
    .profilePicture3All {
        width: 6vw;
        height: 6vw;
    }


    .lastMessage2 {
        padding-left: 9vw !important;
    }


}


@media only screen and (max-width: 421px) {
    .profilePictureAll {
        width: 7vw;
        height: 7vw;
        font-size: 12px;
    }

    .profilePicture2All {
        width: 7vw;
        height: 7vw;
        font-size: 12px;
    }
    .profilePicture3All {
        width: 7vw;
        height: 7vw;
        font-size: 12px;
    }

    .lastMessage2 {
        padding-left: 11.7vw !important;
    }

}

@media only screen and (max-width: 280px) {
    .profilePictureAll {
        width: 9vw;
        height: 9vw;
    }

    .profilePicture2All {
        width: 9vw;
        height: 9vw;
    }
    .profilePicture3All {
        width: 9vw;
        height: 9vw;
    }
    .lastMessage2 {
        padding-left: 15vw !important;
    }

}